type Props = Readonly<{
  children?: React.ReactNode;
  className?: string;
  href?: string;
  title?: string;
}>;

export function ExternalLink({ children, ...rest }: Props): JSX.Element {
  return (
    <a rel="noreferrer" target="_blank" {...rest}>
      {children}
    </a>
  );
}
